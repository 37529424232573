import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { inputState } from "../states/atoms/inputAtom";
import {
  ROUTES,
  STORAGE_KEY,
  PREFECTURES,
  PAYMENTS,
  PATTERN,
  API_ENDPOINT,
} from "../Constants";
import { useAllShop, usePlanOptions } from "../Repositories";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../utils/StorageUtil";
import LoadingComponent from "./loading";
import { useMemo } from "react";
import { useCsrf } from "../Repositories";

const InputPlan = () => {
  const [input, setInput] = useRecoilState(inputState);
  const [isReferralModal, setReferralModal] = useState(false);
  const [isReferralInput, setReferralInput] = useState(false);
  const [referralPlanLoading, setReferralPlanLoading] = useState(false);
  const [referralPlan, setReferralPlan] = useState(null);
  const [referralPlanMessage, setReferralPlanMessage] = useState(null);
  const { token } = useCsrf();
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionAccountId = getSessionStorageItem(STORAGE_KEY.ACCOUNT_ID);
  const accountId =
    searchParams.get(STORAGE_KEY.ACCOUNT_ID) || sessionAccountId;

  if (Number(accountId) !== sessionAccountId) {
    setSessionStorageItem(STORAGE_KEY.ACCOUNT_ID, accountId);
  }

  const navigate = useNavigate();

  const { shops, isShopsLoading, isShopsError } = useAllShop();
  const { planOptions, isPlansLoading, isPlansError } =
    usePlanOptions(accountId);

  const setShopName = (accountId, name) => {
    setSessionStorageItem(STORAGE_KEY.ACCOUNT_ID, accountId);
    setSessionStorageItem(STORAGE_KEY.SHOP_NAME, name);
    document.title = `ワールドプラスジム　${
      !!name ? name + "　" : ""
    }入会のお申し込み｜ワールドプラスジム`;
  };

  const defaultValues = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const account_id = urlParams.get("accountid");
    const shop = shops?.find((shop) => {
      return (shop.accountid_c === Number(accountId) && !account_id) || (account_id && shop.accountid_c === Number(account_id));
    });
    const prefecture = !!shop?.address_c?.match(PATTERN.PREFECTURE)
      ? shop?.address_c?.match(PATTERN.PREFECTURE)[0]
      : "";
    !!shop && setShopName(shop?.accountid_c, shop?.name);
    setReferralInput(!!shop?.referralbenefits_c);
    return {
      shopPrefecture: !!prefecture ? prefecture : input.shopPrefecture,
      shopId: !!shop?.sfid ? shop?.sfid : input.shopId,
      shopName: !!shop?.name ? shop?.name : input.shopName,
      admissionYear: input.admissionYear,
      admissionMonth: input.admissionMonth,
      admissionDay: input.admissionDay,
      paymentMethod: input.paymentMethod,
      referral: input.referral,
      plan: input.plan,
      options: input.options,
    };
  }, [accountId, input, shops]);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  if (isShopsError || isPlansError) return <LoadingComponent />;
  if (isShopsLoading || isPlansLoading) return <LoadingComponent />;

  const shop = !!accountId
    ? shops.find((shop) => {
        return shop.accountid_c === Number(accountId);
      })
    : null;
  const shopName = !shop ? "" : shop.name;
  !!shopName && setSessionStorageItem(STORAGE_KEY.SHOP_NAME, shopName);

  const onSubmit = (data) => {
    const updateInput = {
      shopPrefecture: data.shopPrefecture,
      shopId: data.shopId,
      admissionYear: data.admissionYear,
      admissionMonth: data.admissionMonth,
      admissionDay: data.admissionDay,
      paymentMethod: data.paymentMethod,
      referral: data.referral,
      plan: data.plan,
      options: data.options,
    };
    setInput((currentInput) => ({
      ...currentInput,
      ...updateInput,
    }));
    navigate(ROUTES.profile);
  };

  // Formバリデーションルール
  const VALIDATION_RULES = {
    shopPrefecture: {
      required: "都道府県を選択してください",
    },
    shopId: {
      required: "店舗を選択してください",
    },
    admissionYear: {
      required: "ご入会日(年)を選択してください",
    },
    admissionMonth: {
      required: "ご入会日(月)を選択してください",
    },
    admissionDay: {
      required: "ご入会日(日)を選択してください",
      validate: {
        beforeToday: (value) => {
          if (!!getValues("admissionYear") && !!getValues("admissionMonth")) {
            const msg_today = "本日以降の日付を選択してください";
            const msg_90_day = "本日より90日以内の日付を選択してください";

            // 本日日付の取得
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth() + 1;
            const currentDay = today.getDate();

            const ninetyDaysLater = new Date(today);
            ninetyDaysLater.setDate(today.getDate() + 90);

            // 入力された日付の取得
            const inputYear = Number(getValues("admissionYear"));
            const inputMonth = Number(getValues("admissionMonth"));
            const inputDay = Number(value);
            const inputDate = new Date(inputYear, inputMonth - 1, inputDay);

            if (inputDate > ninetyDaysLater) {
              return msg_90_day;
            } else if (currentYear === inputYear) {
              if (currentMonth === inputMonth) {
                return currentDay <= Number(value) || msg_today;
              } else {
                return currentMonth < inputMonth || msg_today;
              }
            }　else if (inputYear < currentYear) {
              return msg_today;
            }
          }
          return null;
        },
        invalid: (value) => {
          if (!!getValues("admissionYear") && !!getValues("admissionMonth")) {
            // 入力された日付の取得
            const inputYear = getValues("admissionYear");
            const inputMonth = getValues("admissionMonth");
            const date = new Date(inputYear + "/" + inputMonth + "/" + value);
            if (
              Number(inputYear) !== date.getFullYear() ||
              Number(inputMonth) !== date.getMonth() + 1 ||
              Number(value) !== date.getDate()
            ) {
              return "正しい日付を選択してください";
            }

            // 開業日以降の日付を選択判定
            if (shop?.membershipstartdate_c) {
              const m = new Date(shop.membershipstartdate_c.replace(/-/g,"/"));
              const mY = m.getFullYear();
              const mM = m.getMonth() + 1
              const mD = m.getDate();
              const memberShipStartDate = Number(shop.membershipstartdate_c.replace(/-/g,""));
              const choiceDate = Number(`${getValues("admissionYear")}${getValues("admissionMonth").padStart(2, "0")}${value.padStart(2, "0")}`);
              if (memberShipStartDate > choiceDate) {
                return `店舗オープン日（${mY}年${mM}月${mD}日）以降を選択してください`;
              }
            }
          }
          return null;
        },
      },
    },
    paymentMethod: {
      required: "決済方法を選択してください",
    },
    plan: {
      required: "プランを選択してください",
    },
    options: {
      // バリデーションなし
    },
    referral: {
      maxLength: {
        value: 10,
        message: "紹介コードは10文字以内で入力してください",
      },
    },
  };

  const useSelectPref = (event) => {
    setReferralPlan(null);
    setReferralPlanMessage(null);
    setValue('referral', '');
    reset({ shopId: "" });
    event.target.blur();
    trigger(event.target.name);
  };

  /**
   *
   *
   * @param {*} event
   */
  const toggleShopRadio = (event, value) => {
    setReferralInput(!!value.referralbenefits_c);
    setReferralPlan(null);
    setReferralPlanMessage(null);
    setValue('referral', '');
    setValue('admissionYear', '');
    setValue('admissionMonth', '');
    setValue('admissionDay', '');
    setInput((currentInput) => ({
      ...currentInput,
      shopName: value.name,
      shopType: value.shoptype_c,
    }));
    setSearchParams();
    setShopName(value.accountid_c, value.name);
    trigger(event.target.name);
  };

  const togglePaymentMethod = (event) => {
    const filteredPlan = planOptions.plans.filter((plan) => {
      const paymentMethod = event.target.value;
      if (paymentMethod === PAYMENTS.CREDIT_CARD) {
        return plan.card_c;
      } else if (paymentMethod === PAYMENTS.BANK_TRANSFER) {
        return plan.transfer_c;
      } else {
        return false;
      }
    });
    if (filteredPlan.length > 0) {
      setTimeout(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const specialplan = urlParams.get("specialplan");
        if (specialplan) {
          for (let f of filteredPlan) {
            if (f.specialplancode_c === specialplan) {
              document.querySelector(`#${f.sfid}`)?.click();
              setValue("plan", f.sfid);
            }
          }
        } else {
          document.querySelector(`#${filteredPlan[0].sfid}`)?.click();
          setValue("plan", filteredPlan[0].sfid);
        }
      });
      trigger("plan");
    }
    trigger(event.target.name);
  };

  /**
   *
   *
   * @returns
   */
  const getAdmissionYearList = () => {
    let currentYear = new Date().getFullYear();
    const yearList = [""]; // 初期値の空文字を設定
    for (var i = 0; i <= 2; i++) {
      yearList.push(currentYear + i);
    }
    return yearList;
  };

  const fetchReferral = (value) => {
    if (!value) return;
    setReferralPlanLoading(true);
    setReferralPlan(null);
    setReferralPlanMessage(null);
    const headers = {
      "X-CSRFToken": token.token,
      "content-type": "multipart/form-data",
    };
    axios
      .post(
        "/api" + API_ENDPOINT.REFERRAL,
        {
          referralCode: value,
          shopId: shop.sfid,
        },
        { headers: headers, withCredentials: true }
      )
      .then((response) => {
        if (response.data.message) {
          setReferralPlan(null);
          setReferralPlanMessage(response.data.message);
        } else {
          setReferralPlan(response.data.referralPlan);
          setReferralPlanMessage(null);
        }
        setReferralPlanLoading(false);
      });
  }

  const handlerReferralCode = (event) => {
    event.preventDefault();
    fetchReferral(getValues("referral"));
  }

  const handlerReferralChange = (event) => {
    if (referralPlan) {
      fetchReferral(event.target.value);
    }
  }

  const referralModalOpen = () => {
    setReferralModal(true);
  }

  const referralModalClose = (event) => {
    if (event.target.className === 'referral-modal-layer') {
      setReferralModal(false);
    }
  }

  return (
    <main className="main">
      {/* cover */}
      <div className="cover cover--admission cover--bg">
        <div className="cover__outer">
          <div className="cover__inner"></div>
        </div>
      </div>
      {/* /cover */}

      {/* location */}
      <div className="location">
        <p className="location__text">
          <a href={process.env.REACT_APP_WEB_MAIN_URL}>TOP</a>
          <a href={process.env.REACT_APP_WEB_MAIN_URL + "/admission/"}>
            ワールドプラスジム　{!!shopName ? shopName + "　" : ""}
            入会のお申し込み
          </a>
        </p>
      </div>
      {/* /location */}

      {/* content */}
      <div className="content">
        <div className="content__outer">
          <div className="content__inner">
            <h1 className="content__title js-indicate js-indicate-btin">
              ワールドプラスジム　
              {!!shopName ? shopName + "　" : ""}
              入会のお申し込み
            </h1>

            {/* step */}
            <div className="step step--small js-indicate js-indicate-btin">
              <div className="step__lists">
                <div className="step__list step__list--current">
                  <span>
                    プラン<i className="pc">等の</i>選択
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    会員情報<i className="pc">の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    決済情報<i className="pc">の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    <i className="pc">登録</i>完了
                  </span>
                </div>
              </div>
            </div>
            {/* /step */}

            <p className="content__text js-indicate js-indicate-btin">
              必要事項をご記入のうえ、「会員情報の入力へ」ボタンを押してください。
              <br />
              <span className="need">必須</span>は必須項目です。
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="content__h2 content__h2--line js-indicate js-indicate-btin"></div>

              {/* formbox */}
              <div className="formbox js-indicate js-indicate-btin">
                <div className="formbox__item">
                  {!!input.errorMsg && (
                    <p
                      style={{
                        textAlign: "center",
                        margin: "0 30px",
                        padding: "30px 0 25px",
                      }}
                    >
                      <span
                        className="aform-error"
                        style={{ display: "block" }}
                      >
                        {input.errorMsg}
                      </span>
                      <span
                        id="fsjs_error"
                        className="aform-error"
                        style={{ display: "block" }}
                      ></span>
                    </p>
                  )}
                  <dl className="dl_shop">
                    <dt>
                      <label htmlFor="shop" className="aform-label shop">
                        店舗<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select
                        {...register(
                          "shopPrefecture",
                          VALIDATION_RULES.shopPrefecture
                        )}
                        id="shop"
                        className={
                          errors.shopPrefecture
                            ? "form1_pref__select parsley-error"
                            : "form1_pref__select valid"
                        }
                        title="都道府県を選択してください"
                        onChange={useSelectPref}
                      >
                        <option value="">都道府県を選択してください</option>
                        {PREFECTURES.map((value) => (
                          <option value={value.label} key={value.id}>
                            {value.label}
                          </option>
                        ))}
                      </select>
                      <div
                        className={
                          errors.shopId
                            ? "form2_shops parsley-error"
                            : "form2_shops"
                        }
                        style={{
                          display: !!watch("shopPrefecture") ? "block" : "none",
                        }}
                      >
                        {shops.map(
                          (value) =>
                            value.address_c?.includes(watch("shopPrefecture")) >
                              0 && (
                              <div
                                className="form2_shops__item"
                                key={value.sfid}
                              >
                                <input
                                  {...register(
                                    "shopId",
                                    VALIDATION_RULES.shopId
                                  )}
                                  id={value.sfid}
                                  type="radio"
                                  className="form2_shops__radio aform-input aform-radio"
                                  value={value.sfid}
                                  onClick={(event) => {
                                    toggleShopRadio(event, value);
                                  }}
                                />
                                <label
                                  htmlFor={value.sfid}
                                  className="form2_shops__item-info"
                                >
                                  <div className="form2_shops__item-info__name">
                                    {value.name}
                                  </div>
                                  <div className="form2_shops__item-info__addr">
                                    {value.address_c}
                                  </div>
                                  <div className="form2_shops__item-info__tel">
                                    {value.phone_c}
                                  </div>
                                </label>
                                {value.storewebsiteurl_c ? (
                                  <a
                                    href={value.storewebsiteurl_c}
                                    className="form2_shops__link"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    店舗詳細ページ
                                  </a>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )
                        )}
                      </div>
                      <div className="aform-error">
                        {(errors.shopPrefecture || errors.shopId) && (
                          <ul className="parsley-errors-list filled">
                            {errors.shopPrefecture && (
                              <li className="parsley-required">
                                {errors.shopPrefecture.message}
                              </li>
                            )}
                            {errors.shopId && (
                              <li className="parsley-required">
                                {errors.shopId.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>

                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        ご入会日<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd className="formbox__date">
                      <select
                        {...register("admissionYear", {
                          ...VALIDATION_RULES.admissionYear,
                          onChange: () => {
                            if (
                              !!getValues("admissionMonth") &&
                              !!getValues("admissionDay")
                            ) {
                              trigger("admissionDay");
                            }
                          },
                        })}
                        className={
                          errors.admissionYear ||
                          errors.admissionDay?.type === "beforeToday" ||
                          errors.admissionDay?.type === "invalid"
                            ? "date_of_admission parsley-error"
                            : "date_of_admission"
                        }
                      >
                        {getAdmissionYearList().map((value) => (
                          <option value={value} key={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <label>年</label>
                      <br className="sp" />
                      <select
                        {...register("admissionMonth", {
                          ...VALIDATION_RULES.admissionMonth,
                          onChange: () => {
                            if (
                              !!getValues("admissionYear") &&
                              !!getValues("admissionDay")
                            ) {
                              trigger("admissionDay");
                            }
                          },
                        })}
                        className={
                          errors.admissionMonth ||
                          errors.admissionDay?.type === "beforeToday" ||
                          errors.admissionDay?.type === "invalid"
                            ? "date_of_admission parsley-error"
                            : "date_of_admission"
                        }
                      >
                        {[...Array(13)].map((_, i) => (
                          <option value={i === 0 ? "" : i} key={i}>
                            {i === 0 ? "" : i}
                          </option>
                        ))}
                      </select>
                      <label>月</label>
                      <select
                        {...register("admissionDay", {
                          ...VALIDATION_RULES.admissionDay,
                        })}
                        className={
                          errors.admissionDay
                            ? "date_of_admission parsley-error"
                            : "date_of_admission"
                        }
                      >
                        {[...Array(32)].map((_, i) => (
                          <option value={i === 0 ? "" : i} key={i}>
                            {i === 0 ? "" : i}
                          </option>
                        ))}
                      </select>
                      <label>日</label>
                      <div className="aform-error">
                        {(errors.admissionYear ||
                          errors.admissionMonth ||
                          errors.admissionDay) && (
                          <ul className="parsley-errors-list filled">
                            {errors.admissionYear && (
                              <li className="parsley-required">
                                {errors.admissionYear.message}
                              </li>
                            )}
                            {errors.admissionMonth && (
                              <li className="parsley-required">
                                {errors.admissionMonth.message}
                              </li>
                            )}
                            {errors.admissionDay && (
                              <li className="parsley-required">
                                {errors.admissionDay.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                      <div className="formbox__note">
                        <span className="form-description-date">
                          ※施設のご利用や指紋等の登録は、オープン日から可能です。
                          <br />
                        </span>
                        初月会費は、日割り計算になります。
                        <br />
                        (例)1日入会→6570円　10日入会→4161円
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              {/* /formbox */}

              <div className="content__h2 content__h2--line js-indicate js-indicate-btin"></div>

              {/* formbox */}
              {!!planOptions && (
                <div className="formbox js-indicate js-indicate-btin">
                  <div className="formbox__item formbox__card">
                    <dl>
                      <dt>
                        <span>
                          決済方法
                          <span className="aform-required">必須</span>
                        </span>
                      </dt>
                      <dd>
                        <ul className="aform-radio-ul form-payment-type-radio">
                          <li
                            style={{
                              display:
                                !!planOptions &&
                                planOptions.plans.filter((plan) => {
                                  return plan.card_c;
                                }).length > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <input
                              {...register(
                                "paymentMethod",
                                VALIDATION_RULES.paymentMethod
                              )}
                              id="payment01"
                              type="radio"
                              className="jsc-credit"
                              value={PAYMENTS.CREDIT_CARD}
                              onClick={(event) => {
                                togglePaymentMethod(event);
                              }}
                            ></input>
                            <label htmlFor="payment01">クレジットカード</label>
                          </li>
                          <li
                            style={{
                              display:
                                !!planOptions &&
                                planOptions.plans.filter((plan) => {
                                  return plan.transfer_c;
                                }).length > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <input
                              {...register(
                                "paymentMethod",
                                VALIDATION_RULES.paymentMethod
                              )}
                              id="payment02"
                              type="radio"
                              className="jsc-transfer"
                              value={PAYMENTS.BANK_TRANSFER}
                              onClick={(event) => {
                                togglePaymentMethod(event);
                              }}
                            ></input>
                            <label htmlFor="payment02">口座振替</label>
                          </li>
                        </ul>
                        <div id="card-error" className="aform-error"></div>
                      </dd>
                    </dl>
                  </div>
                  {isReferralInput && (
                    <div className="formbox__item formbox__card">
                      <dl>
                        <dt>
                          <span>
                            紹介コード
                            <span onClick={referralModalOpen} className="question-modal">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4922 7.99927C15.4922 9.48263 15.0523 10.9327 14.2282 12.166C13.4041 13.3994 12.2328 14.3607 10.8623 14.9284C9.49187 15.496 7.98387 15.6445 6.52901 15.3552C5.07416 15.0658 3.73778 14.3515 2.68889 13.3026C1.64 12.2537 0.92569 10.9173 0.636301 9.46244C0.346912 8.00759 0.495437 6.49959 1.06309 5.12914C1.63075 3.7587 2.59205 2.58736 3.82541 1.76325C5.05878 0.939135 6.50883 0.499268 7.99219 0.499268C8.9771 0.499268 9.95237 0.693261 10.8623 1.07017C11.7723 1.44708 12.5991 1.99953 13.2955 2.69597C13.9919 3.39241 14.5444 4.2192 14.9213 5.12914C15.2982 6.03908 15.4922 7.01435 15.4922 7.99927ZM13.9922 7.99927C13.9922 9.18595 13.6403 10.346 12.981 11.3327C12.3217 12.3194 11.3846 13.0884 10.2883 13.5425C9.19193 13.9967 7.98553 14.1155 6.82165 13.884C5.65776 13.6525 4.58866 13.081 3.74955 12.2419C2.91043 11.4028 2.33899 10.3337 2.10748 9.16981C1.87597 8.00592 1.99479 6.79952 2.44891 5.70317C2.90304 4.60681 3.67207 3.66974 4.65877 3.01045C5.64546 2.35116 6.8055 1.99927 7.99219 1.99927C9.58349 1.99927 11.1096 2.63141 12.2348 3.75663C13.36 4.88184 13.9922 6.40797 13.9922 7.99927Z" fill="#BBA15C"/>
                                <path d="M7.124 9.976C6.836 8.044 8.876 7.264 8.876 6.124C8.876 5.524 8.48 5.14 7.856 5.14C7.328 5.14 6.908 5.416 6.524 5.812L5.552 4.924C6.176 4.216 7.028 3.724 8.072 3.724C9.512 3.724 10.568 4.468 10.568 6.004C10.568 7.708 8.48 8.284 8.66 9.976H7.124ZM7.892 13.168C7.268 13.168 6.8 12.664 6.8 12.016C6.8 11.356 7.268 10.864 7.892 10.864C8.528 10.864 8.996 11.356 8.996 12.016C8.996 12.664 8.528 13.168 7.892 13.168Z" fill="#CBA457"/>
                              </svg>
                            </span>
                          </span>
                        </dt>
                        <dd>
                          <ul className="aform-zip-ul">
                            <li>
                              <input
                                {...register("referral", VALIDATION_RULES.referral)}
                                type="text"
                                onInput={handlerReferralChange}
                                className={
                                  (errors.referral || referralPlanMessage)
                                    ? "referral-code parsley-error"
                                    : "referral-code"
                                }
                              ></input>
                              <div className="formbox__note">
                                （半角）
                              </div>
                            </li>
                            <li>
                              <a
                                href="#referral"
                                className="referral-button button"
                                onClick={handlerReferralCode}
                              >
                                <span className="button__label">適用する</span>
                              </a>
                            </li>
                          </ul>
                          <span className={referralPlanLoading ? "fetch-loading show": "fetch-loading"}></span>
                          {referralPlan && (
                            <div className="referral-plan-content">
                              <p className="referral-plan-content-head">紹介特典</p>
                              <div className="referral-plan-content-inner">
                                <p className="referral-plan-content-name">{referralPlan.name}</p>
                                <p className="referral-plan-content-description">{referralPlan.benefitdescription_c}</p>
                              </div>
                            </div>
                          )}
                          <div id="referral-error" className="aform-error">
                            {referralPlanMessage && (
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">
                                  {referralPlanMessage}
                                </li>
                              </ul>
                            )}
                            {errors.referral && (
                              <ul className="parsley-errors-list filled">
                                {errors.referral && (
                                  <li className="parsley-required">
                                    {errors.referral.message}
                                  </li>
                                )}
                              </ul>
                            )}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  )}
                  <div
                    className="formbox__item formbox__plan"
                    style={{
                      display: watch("paymentMethod") === "" ? "none" : "block",
                    }}
                  >
                    <dl>
                      <dt>
                        <label>
                          プラン<span className="aform-required">必須</span>
                        </label>
                      </dt>
                      <dd>
                        <table className="form-campaign-radio-input">
                          <tbody>
                            {planOptions.plans
                              .filter((plan) => {
                                if (!referralPlan && !plan.specialplancode_c) {
                                  return plan.referralbenefitsplan_c === false
                                } else {
                                  return true;
                                }
                              })
                              .filter((plan) => {
                                const paymentMethod = watch("paymentMethod");
                                if (paymentMethod === PAYMENTS.CREDIT_CARD) {
                                  return plan.card_c;
                                } else if (
                                  paymentMethod === PAYMENTS.BANK_TRANSFER
                                ) {
                                  return plan.transfer_c;
                                } else {
                                  return false;
                                }
                              })
                              .sort((item1, item2) => {
                                if (item1.referralbenefitsplan_c === true && item2.referralbenefitsplan_c === false) {
                                  return -1;
                                } else if (item1.referralbenefitsplan_c === false && item2.referralbenefitsplan_c === true) {
                                  return 1;
                                } else {
                                  return 0;
                                }
                              })
                              .map((filteredPlan, i) => (
                                <tr key={i}>
                                  <th>
                                    <input
                                      {...register(
                                        "plan",
                                        VALIDATION_RULES.plan
                                      )}
                                      id={filteredPlan.sfid}
                                      type="radio"
                                      value={filteredPlan.sfid}
                                      onClick={() => {
                                        setSessionStorageItem(
                                          STORAGE_KEY.SELECTED_PLAN,
                                          filteredPlan
                                        );
                                      }}
                                    />
                                    <label htmlFor={filteredPlan.sfid}>
                                      {filteredPlan.name}
                                    </label>
                                  </th>
                                  <td>
                                    {filteredPlan.description_c && filteredPlan.description_c
                                      ?.split(/\n|<br>|<br\/>|<br \/>/)
                                      .map((str, i) => {
                                        return (
                                          <React.Fragment key={i}>
                                            {str}
                                            <br />
                                          </React.Fragment>
                                        );
                                      })}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <div id="plan-error" className="aform-error"></div>
                      </dd>
                    </dl>
                  </div>
                  {planOptions.options.length !== 0 && (
                    <div
                      className="formbox__item "
                      style={{
                        display: watch("paymentMethod") === "" ? "none" : "block",
                      }}
                    >
                      <dl>
                        <dt>
                          <label>オプション</label>
                        </dt>
                        <dd>
                          {planOptions.options.map((option, i) => (
                            <div key={i}>
                              <label htmlFor={`options.${i}`}>
                                <input
                                  id={`options.${i}`}
                                  type="checkbox"
                                  placeholder="check"
                                  value={option.sfid}
                                  {...register(
                                    `options.${i}`,
                                    VALIDATION_RULES.options
                                  )}
                                />
                                {`${option.name}(${Number(
                                  option.amount_c
                                ).toLocaleString()}円)`}
                              </label>
                              {option.description &&
                                <p className="formbox__note">
                                  {option.description
                                    .split(/\n|<br>|<br\/>|<br \/>/)
                                    .map((str, ii) => {
                                      return (
                                        <React.Fragment key={ii}>
                                          {str}
                                          <br />
                                        </React.Fragment>
                                      );
                                    })
                                  }</p>}
                              <br />
                              <br className="pc" />
                            </div>
                          ))}
                        </dd>
                      </dl>
                    </div>
                  )}
                </div>
              )}
              {/* /formbox */}

              {/* message */}
              <div className="js-indicate js-indicate-btin">
                <p className="message__guide">
                  ご不明な点は{" "}
                  <a href={process.env.REACT_APP_WEB_MAIN_URL + "/contact/"}>
                    こちら
                  </a>{" "}
                  にお問い合わせください。
                </p>
              </div>
              {/* /message */}

              {/* buttons */}
              <div className="buttons js-indicate js-indicate-btin">
                <button
                  type="submit"
                  className="button button--large button--arrow js-submit"
                  disabled={!isValid}
                >
                  <span className="button__label">会員情報の入力へ</span>
                </button>
              </div>
              {/* /buttons */}

              {/* formbox */}
              {!!planOptions &&
                planOptions.plans
                  .filter((plan) => {
                    return watch("plan") === plan.sfid;
                  })
                  .map((filteredPlan, i) => (
                    <div
                      key={filteredPlan.sfid}
                      className="formbox js-indicate js-indicate-btin"
                    >
                      <div className="formbox__item formbox__price formbox__price--first">
                        <dl>
                          <dt>
                            <label>各種料金</label>
                          </dt>
                          <dd>
                            <table>
                              <thead>
                                <tr>
                                  <th colSpan="2" className="campaign_name">
                                    {filteredPlan.name}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>入会金</th>
                                  <td className="admission_fee">
                                    ¥
                                    {Number(
                                      filteredPlan.admissionfee_c
                                    ).toLocaleString()}
                                  </td>
                                </tr>
                                <tr>
                                  <th>事務手数料</th>
                                  <td className="commission">
                                    ¥
                                    {Number(
                                      filteredPlan.administrativefee_c
                                    ).toLocaleString()}
                                  </td>
                                </tr>
                                {filteredPlan.annualcontractplan_c ? (
                                  <tr>
                                    <th>年会費</th>
                                    <td className="monthly_fee">
                                      ¥
                                      {Number(
                                        filteredPlan.initialfee_c
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                ): (
                                  <tr>
                                    <th>月会費</th>
                                    <td className="monthly_fee">
                                      ¥
                                      {Number(
                                        filteredPlan.monthlyfee_c
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                )}
                                {!filteredPlan.annualcontractplan_c && (
                                  <tr
                                    className="per-diem_price"
                                    style={{
                                      display:"table-row",
                                    }}
                                  >
                                    <th>月会費（1日あたり）</th>
                                    <td className="monthly_fee-21">
                                      ¥{filteredPlan.proratedmonthlyfee_c}
                                    </td>
                                  </tr>
                                )}
                                <tr
                                  className="free_period"
                                  style={{
                                    display:
                                      filteredPlan.freestartmonth_c === 0 &&
                                      filteredPlan.freemonthperiod_c === 0
                                        ? "none"
                                        : "table-row",
                                  }}
                                >
                                  <th>月会費無料期間</th>
                                  {filteredPlan.freestartmonth_c === 0 ? (
                                    <td>
                                      初月から{filteredPlan.freemonthperiod_c}
                                      ヶ月間無料
                                    </td>
                                  ) : (
                                    <td>
                                      入会月の{filteredPlan.freestartmonth_c}
                                      ヶ月後から{filteredPlan.freemonthperiod_c}
                                      ヶ月間無料
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                            <div className="formbox__note">
                              ＊すべて税込価格になります。
                              <br />
                              ＊月途中での入会の場合、月末までの残日数×月会費（1日あたり）の計算結果が初月分のお支払い金額となります。
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  ))}

              {/* /formbox */}
            </form>
            {/* <% end %> */}
          </div>
        </div>
      </div>
      {/* /content */}

      {isReferralModal && (
        <div className="referral-modal-layer" onClick={referralModalClose}>
          <div className="modal-layer-inner">
            <div className="modal-content">
              <p className="modal-content-head">お友達紹介について</p>
              <p className="modal-content-text">
                ご家族・ご友人などをご紹介いただき、ご入会された場合、ご紹介者様も、ご入会者様も、すてきな特典をプレゼント！
                <br/>
                <br/>
                マイページに表示されているあなたの「紹介コード」をご入会者様にお伝えし、WEB入会画面で入力するだけ！これでお得な特典がゲットできます！
                <br/>
                <br/>
                是非この機会にご家族・ご友人、大切な方をお誘いください！
                <br/>
                <br/>
                ※ご紹介者様の所属店舗に入会される場合のみ、特典を受けられます。
                <br/>
                ※紹介の特典は店舗ごとに異なります。
                <br/>
                ※紹介特典の詳細については、店舗スタッフ又は受付近くに設置しておりますモニターまでお問合せください。
                <br/>
                ※再入会には適応されません。</p>
            </div>
            <svg class="modal-close-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M12.3975 0.26232C12.5783 0.0944597 12.8173 0.00321534 13.064 0.00776998C13.3108 0.0123246 13.5462 0.112323 13.7207 0.286742C13.8952 0.461161 13.9953 0.696413 13.9998 0.943036C14.0044 1.18966 13.9131 1.42844 13.7451 1.60918L8.35352 6.99764L13.7451 12.3861C13.9131 12.5668 14.0044 12.8056 13.9998 13.0522C13.9953 13.2989 13.8952 13.5341 13.7207 13.7085C13.5462 13.883 13.3108 13.983 13.064 13.9875C12.8173 13.9921 12.5783 13.9008 12.3975 13.733L7.00587 8.34551L1.61325 13.735L1.5952 13.752C1.41234 13.9182 1.17205 14.007 0.924984 13.9996C0.677917 13.9922 0.443381 13.8892 0.270824 13.7123C0.0982674 13.5354 0.00117266 13.2985 1.05527e-05 13.0515C-0.00115156 12.8044 0.0937095 12.5666 0.264595 12.3881L5.65621 6.99965L0.265597 1.61019C0.0940411 1.4301 -0.000300417 1.19015 0.00270483 0.941514C0.00571008 0.692884 0.105824 0.455272 0.281683 0.279386C0.457542 0.103499 0.695218 0.00326687 0.943992 7.84523e-05C1.19277 -0.00310996 1.43294 0.0909978 1.61325 0.26232L7.00487 5.65078L12.3975 0.26232Z" fill="white"/>
            </svg>
          </div>
        </div>
      )}
    </main>
    // /main
  );
};

export default InputPlan;
